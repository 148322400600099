var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['fold_item', _vm.isFold ? 'folded':'opened'  ]},[_c('div',{staticClass:"head_fold",on:{"click":function($event){return _vm.onClickFold()}}},[(( _vm.orderNum && _vm.itemData[_vm.orderNum] ) || _vm.listStyle)?_c('span',{staticClass:"txt_num"},[_vm._v(_vm._s(_vm.orderNum && _vm.itemData[_vm.orderNum] ? _vm.itemData[_vm.orderNum] : _vm.listStyle))]):_vm._e(),(_vm.itemData[_vm.iconTitle])?_c('span',{staticClass:"icon_title",style:({
        width: `${_vm.itemData[_vm.iconTitle].size}px`,
        height: `${_vm.itemData[_vm.iconTitle].size}px`,
        background: `url(${_vm.itemData[_vm.iconTitle].url}) no-repeat 0 0`,
        backgroundSize: `${_vm.itemData[_vm.iconTitle].size}px ${_vm.itemData[_vm.iconTitle].size}px`,
        marginRight: `${_vm.itemData[_vm.iconTitle].marginRight}px`,
      })}):_vm._e(),_c('strong',{staticClass:"tit_fold",domProps:{"innerHTML":_vm._s(_vm.itemData[_vm.title])}}),_c('IconSvg',{attrs:{"iconName":"arrow_fold","size":24,"iconColor":"#A6A4A4"}})],1),_c('div',{staticClass:"body_fold"},[(_vm.desc)?_c('div',{staticClass:"inner_body_fold",domProps:{"innerHTML":_vm._s(_vm.itemData[_vm.desc])}}):[_c('div',{staticClass:"inner_body_fold"},[_vm._t("default")],2)]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }